<template>
  <el-dialog
    :title="$t('job.logList')"
    :append-to-body="true"
    :modal-append-to-body="false"
    :visible.sync="visible"
    width="75%"
  >
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input
           v-model="dataForm.id"
           @input="forceUpdate" 
          :placeholder="$t('job.taskID')"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="searchDataList()"
          :loading="searchLoading"
          >{{ searchLoading ? $t('querying') : $t('query') }}</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      height="460"
      style="width: 100%;"
    >
      <el-table-column
        prop="logId"
        header-align="center"
        align="center"
        width="80"
        :label="$t('job.logID')"
      >
      </el-table-column>
      <el-table-column
        prop="jobId"
        header-align="center"
        align="center"
        width="80"
        :label="$t('job.taskID')"
      >
      </el-table-column>
      <el-table-column
        prop="beanName"
        header-align="center"
        align="center"
        :label="$t('job.beanName')"
      >
      </el-table-column>
      <el-table-column
        prop="methodName"
        header-align="center"
        align="center"
        :label="$t('job.methodName')"
      >
      </el-table-column>
      <el-table-column
        prop="params"
        header-align="center"
        align="center"
        :label="$t('job.parameter')"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        :label="$t('job.state')"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small">{{ $t('success') }}</el-tag>
          <el-tag
            v-else
            @click.native="showErrorInfo(scope.row.logId)"
            size="small"
            type="danger"
            style="cursor: pointer;"
            >{{ $t('failed') }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="times"
        header-align="center"
        align="center"
        :label="$t('job.useTime')+'('+$t('job.unit')+':'+$t('job.unitFor')+')'"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="180"
        :label="$t('job.executionTime')"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </el-dialog>
</template>

<script>
import { logList,getLogById } from '@/api/job/schedule'
export default {
  data() {
    return {
      visible: false,
      searchLoading: false,
      dataForm: {
        id: ""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false
    };
  },
  methods: {
    forceUpdate() {
      this.$forceUpdate()
    },
    init(jobIds) {
      this.dataForm.id = jobIds[0];
      this.visible = true;
      this.getDataList();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.searchLoading = true;
      //  
      logList({
          page: this.pageIndex,
          limit: this.pageSize,
          jobId: this.dataForm.id
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list;
            this.totalCount = data.data.totalCount;
          } else {
            this.$message.error(data.msg);
          } 
        })
        .finally(() => {
          this.dataListLoading = false;
          this.searchLoading = false;
        });
    },
    searchDataList() {
      this.pageIndex = 1;
      this.getDataList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 失败信息
    showErrorInfo(id) { 
      getLogById(id).then(({ data }) => {
          if (data && data.code === 0) {
            this.$alert(data.data.error);
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.el-tag--small,
.el-button--small.is-round {
  padding: 3px 6px;
  display: inline;
}
</style>
